<template>
    <div class="cloud-import-preview">
        <div class="cloud-import-preview__sheet-container">
            <BaseButton
                v-for="(number, index) in sheetCountArray"
                :key="index"
                :disabled="number === sheetIndex"
                view="secondary"
                form="square"
                @click="onChangeSheet(number)"
            >{{(number + 1)}}</BaseButton>
        </div>

        <AppTable
            primaryKey="id"
            :columns="columns"
            :rows="rows"
            class="cloud-import-preview__table"
            @lazyload="onLazyload"
        >
            <template
                v-for="(column, index) in slotColumns"
                v-slot:[column]="{row}"
            >
                <div
                    :key="index"
                    :style="{
                        background: getCellProperty(row, column, 'color'),
                        width: '100%',
                        height: '100%'
                    }"
                >
                    <div>{{getCellProperty(row, column, 'val')}}</div>
                </div>
            </template>
        </AppTable>
    </div>
</template>

<script>
import AppTable from '@/components/Table2/Table.vue';
import ServiceCloudImport from "@/services/ServicesCloudImport/ServiceCloudImport";
import BaseButton from "@/components/Base/BaseButton.vue";
export default {
	name: "PageCloudImportFilePreview",
	components: {
        AppTable,
        BaseButton
	},
    /**
     * Входные данные компонента
     */
    props: {
        fileId: {
            type: Number,
            default:0
        },
        sheetIndex: {
            type: Number,
            default: 0
        }
    },
	data() {
		return {
            columns: [],
            rows: [],
            page: null,
            pageCount: null,
            sheetCount: 0
		}
	},
	computed: {
        slotColumns() {
            return this.columns.reduce((resultArray, item) => {
                const {isSlot = false, prop = null} = item;
                if(isSlot){
                    resultArray.push(prop)
                }
                return resultArray;
            }, [])
        },

        sheetCountArray() {
            // Создаем массив чисел от 1 до sheetCounts
            return Array.from({ length: this.sheetCount }, (_, i) => i);
        }
	},
    beforeMount() {
        this.page = 1;
    },
    created() {

	},
	methods: {
        onLazyload(){
            if(this.page < this.pageCount){
                this.page++;
            }
        },

        onChangeSheet(sheetIndex){
            this.sheetIndex = sheetIndex;
            this.page = null;
        },

        getCellProperty(row, column, property) {
            if(Object.keys(row).includes(column)){
                return row[column][property] ?? ''
            }
            return '';
        }
	},
	watch: {
        page() {
            if(this.page === null){
                this.rows = [];
                this.page = 1;
                return;
            }

            ServiceCloudImport.preview(this.fileId, this.sheetIndex, this.page, (result) => {
                const {pageCount = 0, items = [], columns = [], sheetCount = 0} = result;
                this.rows = [...this.rows, ...items];
                this.columns = columns;
                this.pageCount = pageCount;
                this.sheetCount = sheetCount
            }, () => {});
        }
	}
}
</script>

<style lang="scss" scoped>
.cloud-import-preview {

    &__table {
        width: 100%;
        height: auto;
        max-height: 100vh;
    }

    &__sheet-container {
        display: flex;
        margin-bottom: 20px;
    }

    &__sheet-container .btn{
        margin: 2px;
        padding: 6px;
    }
}
</style>
